<template>
    <div class="picture-dialog-wrapper">
        <v-row class="no-gutters">
            <v-col cols="auto">
                <v-dialog
                    v-model="dialogVisible"
                    fullscreen
                    hide-overlay
                    scrollable>
                    <v-card tile>
                    <v-card-text class="text-center picture-dialog-card pa-0">
                       <v-container fill-height fluid class="pa-0 img-container">
                            <v-row align="center" no-gutters
                                justify="center">
                                <v-col class="pa-0">
                                    <v-btn v-if="dialogVisible"
                                        outlined 
                                        fab
                                        dark
                                        elevation="2"
                                        @click="close()"
                                        class="mx-2 remove-image"
                                        x-small><v-icon>mdi-close</v-icon></v-btn> 
                                    <transition>
                                        <img v-show="isLoad" :src="loadedSource" @load="loaded">
                                    </transition>
                                    <div v-show="!isLoad" class="loading">
                                        <v-row no-gutters
                                            class="fill-height ma-0 placeholder-image"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5">
                                            </v-progress-circular>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                            </v-container>
                    </v-card-text>
                    <!-- <div style="flex: 1 1 auto; background: black"></div> -->
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'PictureDialog',
    components: {
    },
    data: () => ({
        loadedSource: '',
        isLoad: false
    }),
    watch: {
        dialogVisible: {
            immediate: true,
            handler() {
                this.onDialogVisible();
            }
        },
    },
    computed: {
        ...mapState({
            dialogVisible: state => state.dialog.isPictureDialogVisible,
            imgSrc: state => state.dialog.imgSrc
        }),
        dialogVisible: {
            get() {
                return this.$store.state.dialog.isPictureDialogVisible;
            },
            set(newValue) {
                this.$store.commit('dialog/setPictureDialogVisibility', newValue);
            }
        },
    },
    methods: {
        onDialogVisible()
        {
            if (!this.dialogVisible) return;
            this.loadStart();
            this.$nextTick(() => {
                this.loadedSource = this.imgSrc;

                //fallback
                setTimeout(() => {
                    this.loaded();
                }, 5000);
            })
        },
        loadStart()
        {
            this.isLoad = false;
            this.loadedSource = "";
        },
        loaded() {
            this.isLoad = true
        },
        close() {
            this.$store.commit('dialog/hidePictureDialog');
        }
    }
  }
</script>
<style>

.remove-image
{
    background: #00000036;
    border-radius: 33px !important;
    position: absolute;
    right: 15px;
    top: 25px;
}

.v-dialog
{
    margin: 0px !important;
    box-shadow: none;
}

.picture-dialog-card
{
    background: black;
}

.picture-dialog-card img {
    object-fit: contain;
    width: 100%;
    height: 100vh;
}

.picture-dialog-card .img-container
{
    overflow: hidden;
    max-width: 100% !important;
}

.picture-dialog-header .v-toolbar__content
{
    height: 35px !important;
}

</style>