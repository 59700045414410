<template>
    <v-container>
        <LoadingIcon :isLoading="isLoading" />
        <v-container class="trip-info" v-show="!isLoading">
            <template>
                <div class="">
                    <v-container class="pa-0">
                        <v-row no-gutters>
                            <v-col class="col-12 trip-galerij-header  py-0">{{currentTrip.tripName}}</v-col>
                            <v-col class="col-12 trip-galerij-subtitle">{{formatDayMonthYear(getDateOnly(currentTrip.tripDate))}}</v-col>
                        </v-row>
                    </v-container>
                    <v-container class="pa-0">
                        <v-row no-gutters>
                            <v-col class="col-12 rating pa-0 d-flex">
                                <v-rating
                                    v-model="currentTrip.averageRating"
                                    background-color="#b5b5b5"
                                    color="yellow accent-4"
                                    half-increments
                                    dense
                                    readonly
                                    size="22"></v-rating>
                                <span class="comments">{{currentTrip.averageRating}} <span class="pl-1">({{currentTrip.numberOfRatings}})</span></span>
                            </v-col>
                            <v-col class="col-12 py-0 intro">{{currentTrip.roadtripGalleryText}}</v-col>
                        </v-row>
                    </v-container>
                    <v-container class="tab-section px-0">
                        <v-tabs
                            v-model="tab"
                            background-color="transparent"
                            color="basil"
                            centered
                            grow>
                            <v-tab
                                v-for="item in items"
                                :key="item">
                                {{ item }}
                            </v-tab>
                        </v-tabs>
                    </v-container>
                </div>
            </template>
        </v-container>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <div class="mb-3">
                    <v-row no-gutters>
                         <template v-for="(image, image_index) in paginatedPhotos">
                            <v-col :key="image_index" class="col-lg-4 col-md-4 col-sm-12 col-xs-12 photo-container" cols="12">
                                <div class="photo-review-box mb-4">
                                    <ImageRedirect :imageSource="image.photoSmall" :imgClass="'photoImage'" @imgClick="showPictureDialog(image.photoLarge)"/>
                                </div>
                                <div class="like-counter pr-3" align="center" justify="end">
                                    <span class="subheading mr-2">{{image.likeCount}} {{ image.likeCount == 1 ? `like` : `likes`}}</span>
                                    <v-btn icon @click.stop="heartClicked(image)" :disabled="isLikingPhoto && currentLikedPhotoId == image.photoId" :class="{liked : image.likedByCurrentAccount}">
                                        <v-icon>{{ image.likedByCurrentAccount ? `mdi-heart` : `mdi-heart-outline`}}</v-icon>
                                    </v-btn>
                                    
                                </div>
                            </v-col>
                        </template>
                    </v-row>
                    <v-container v-show="!isLoading && paginatedPhotos.length == 0">
                        <v-row no-gutters>
                            <v-col class="col-12 display-1 py-0">Foto's</v-col>
                            <v-col class="col-12 py-5 intro" v-if="paginatedPhotos.length == 0">Nog geen foto's.</v-col>
                        </v-row>
                    </v-container>
                    <div class="text-center my-5" v-show="!isLoading && paginatedPhotos.length > 0">
                        <v-pagination v-model="pageNumber" :length="pageCount" circle @input="nextPage" :total-visible="7"></v-pagination>
                    </div>
                </div>
            </v-tab-item>
            <v-tab-item>
                <v-container v-if="!isLoading">
                    <template>
                        <div class="">
                            <v-container class="pa-0">
                                <v-row no-gutters>
                                    <v-col class="col-12 display-1 py-0">Reviews</v-col>
                                    <v-col class="col-12 py-5 intro" v-if="reviews.length == 0">Geen reviews.</v-col>
                                </v-row>
                            </v-container>
                            <template v-for="(review, review_index) in reviews" >
                                <v-container class="pa-0 pt-3" :key="review_index">
                                <v-row no-gutters>
                                    <v-col class="col-5 header-title py-0">{{review.firstName}}</v-col>
                                    <v-col class="col-7 text-end d-flex justify-end review-date pt-1">
                                        <div>
                                            Geplaatst op {{formatDayMonthYear(getDateOnly(review.placed))}}
                                        </div>
                                        <div v-if="review.isOwnReview && status.loggedIn" class="ml-1 d-flex justify-end">
                                            <div v-if="!isOnEditOwnReviewMode" class="mx-3 mt-1">
                                                <img class="icon pointer" width="20" height="20" :src="editIcon" @click="editReview()">
                                            </div>
                                            <v-icon v-if="isOnEditOwnReviewMode" @click="updateReview(review)" class="mt-n3 mr-2"
                                                right size="30"
                                                dark>
                                                mdi-check
                                            </v-icon>
                                            <div class="mx-3 mt-1">
                                                <img class="pointers" width="25" :src="deleteIcon" alt="Delete" @click="showDialog(review, review_index)" />
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col class="col-12 rating pa-0 d-flex">
                                        <v-rating
                                            :readonly="!review.isOwnReview || !isOnEditOwnReviewMode"
                                            v-model="review.starRating"
                                            background-color="#b5b5b5"
                                            color="yellow accent-4"
                                            half-increments
                                            dense
                                            hover
                                            size="18"></v-rating>
                                        <span class="comments">{{review.starRating}}</span>
                                    </v-col>
                                    <v-col class="col-12 review-text">
                                        <span v-if="!review.isOwnReview || !isOnEditOwnReviewMode">{{review.textRating}}</span>
                                        <v-text-field v-model="review.textRating" v-show="review.isOwnReview && isOnEditOwnReviewMode" 
                                            @keyup.enter.stop="updateReview(review)"
                                            hide-details
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                            </template>
                            <v-container class="pa-0">
                                <v-row no-gutters v-if="!status.loggedIn">
                                    <v-col class="col-12 pb-3">
                                        <v-divider color="white"></v-divider>
                                    </v-col>
                                    <v-col class="col-12 pb-5">
                                        Log in om een review te plaatsen.
                                        <v-btn color="primary" block class="mt-5 mb-5" @click="goToLogin($router.currentRoute.path)">
                                            Login
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters v-if="canPlaceReview">
                                    <v-col class="col-12 display-1 py-0">Plaats een review</v-col>
                                    <v-col class="col-12 rating pa-0 d-flex">
                                        <v-rating
                                            :readonly="isSubmittingReview"
                                            v-model="newRating"
                                            background-color="#b5b5b5"
                                            color="yellow accent-4"
                                            half-increments
                                            dense
                                            hover
                                            size="18"></v-rating>
                                            <span class="comments">{{newRating}}</span>
                                    </v-col>
                                    <v-col class="col-12 pb-5">
                                        <v-text-field v-model="newReview"
                                            :disabled="isSubmittingReview"
                                            label="Schrijf hier uw review."
                                            required></v-text-field>
                                        <v-btn color="primary" block @click="submitReview()" class="mt-5 mb-5" :disabled="isSubmittingReview || newReview == ``">
                                            Plaats review
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                    </template>
                </v-container>
        </v-tab-item>
    </v-tabs-items>
        <v-dialog v-if="selectedReview"
            v-model="dialog"
            max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    Bevestig verwijderen
                </v-card-title>

                <v-card-text>
                    Weet u zeker dat u uw review wilt verwijderen?
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    :disabled="isRemoving"
                    @click="dialog = false">
                    Annuleren
                </v-btn>

                <v-btn
                    text
                    :loading="isRemoving"
                    @click="deleteReview()">
                    Verwijderen
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <PictureDialog />
    </v-container>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon';
import ImageRedirect from '@/components/common/ImageRedirect';
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import PictureDialog from "@/components/dialogs/PictureDialog";
import MetaTagsMixin from '@/mixins/metatags.mixin';

import { mapState } from 'vuex'
export default {
    name: 'EventReviews',
    components: 
    {
        ImageRedirect,
        LoadingIcon,
        PictureDialog
    },
    mixins: [FormatMixin, RouteMixin, MetaTagsMixin],
    data: () => ({
        editIcon: require('@/assets/icons/edit.svg'),
        deleteIcon: require('@/assets/icons/delete.svg'),
        roadtripId: '',
        isLoading: true,
        currentTrip: {
            photos: []
        },
        reviews: [],
        newRating: 0.0,
        newReview: '',
        isSubmittingReview: false,
        pageNumber: 1,
        size: 12,
        tab: null,
        items: [
          'Galerij', 'Reviews'
        ],
        canPlaceReview: false,
        isOnEditOwnReviewMode: false,
        selectedReview: null,
        dialog: false,
        isRemoving: false,
        isLikingPhoto: false,
        currentLikedPhotoId: '',
    }),
    computed: {
        ...mapState('account', ['status', 'user']),
        ...mapState('authorizedModule', ['userId']),
        pageCount() {
            let l = this.currentTrip.photos.length,
                s = this.size;
            return Math.ceil(l / s);
        },
        paginatedPhotos() {
            const start = this.pageNumber * this.size - this.size,
                end = start + this.size;
            return this.currentTrip.photos.slice(start, end);
        },
        metaPhoto() {
            if (this.currentTrip.photos.length > 0)
            {
                return this.currentTrip.photos[0].photoSmall;
            }

            return '';
        }
    },
    metaInfo() {
        return {
        title: this.currentTrip.tripName ? `| ${this.currentTrip.tripName}` : ``,
        meta: [
            this.getKeywords(`Volvo, Porsche, Mix, galerij`),
            this.getMetaDescription(`Roadpursuit galerij en reviews - ${this.currentTrip.tripName}`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`${this.currentTrip.tripName}`, `Roadpursuit galerij en reviews - ${this.currentTrip.tripName}`, this.metaPhoto, `Roadpursuit galerij - ${this.currentTrip.tripName}`)
        ]
        };
    },
    watch: {
        userId: {
            immediate: true,
            handler() {
                this.uniqueName = this.$route.params.trip;
                this.getRoadtripId();
            }
        }
    },
    mounted() {
        this.$store.commit('setFooterVisibility', true);
    },
    methods: 
    {
        getRoadtripId()
        {
            this.$store.dispatch('roadtripModule/getRoadtripId', this.uniqueName)
                .then(data => {
                    this.roadtripId = data;
                    this.getTripGallery();
            })
            .catch(() => { 
                this.$store.commit('dialog/showDialog', `Unable to retrieve roadtrip.`);
                this.isLoading = false; 
            });
        },
        getTripGallery()
        {
            this.likingPhotos = [];
            if(this.userId != '')
            {
                 const request = {
                        accountId: this.userId,
                        roadtripId: this.roadtripId
                    };

                this.isLoading = true;
                this.$store.dispatch('galleryModule/GetRoadtripGalleryLoggedIn', request)
                    .then(data => {
                    this.currentTrip = data;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });

                this.checkIfCanPlaceReviews(request);
            }
            else
            {
                this.isLoading = true;
                this.$store.dispatch('galleryModule/getRoadtripGalleryLoggedOut', this.roadtripId)
                    .then(data => {
                    this.currentTrip = data;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
            }

            this.getReviews();
        },
        getReviews()
        {
            this.$store.dispatch('reviewModule/getAllReviewsFromRoadtrip', this.roadtripId)
                .then(data => {
                    this.reviews = [];
                    data.forEach(review => {
                        review.date = this.getDateOnly(review.placed);

                        review.isOwnReview = this.userId == review.accountId;
                        this.reviews.push(review);
                    });
            })
            .catch(() => { this.isLoading = false; });
        },
        checkIfCanPlaceReviews(request)
        {
            this.$store.dispatch('reviewModule/canPlaceReview', request)
                .then(result => {
                    this.canPlaceReview = result;
            })
        },
        submitReview()
        {
            if(!this.status.loggedIn) {
                this.$store.commit('dialog/showDialog', "Login verplicht.");
            }

            this.isSubmittingReview = true;
            const request = {
                    roadtripId: this.roadtripId,
                    accountId: this.userId,
                    starRating: this.newRating,
                    textRating: this.newReview
                };

            this.$store.dispatch('reviewModule/placeReview', request)
                .then(data => {
                    data.isOwnReview = true;
                    this.reviews.push(data);
                    this.newRating = 0.0;
                    this.newReview = "";
                    this.isSubmittingReview = false;
                    this.canPlaceReview = false; // can only review once.
                })
                .catch(error => {
                    this.isSubmittingReview = false;
                    this.$store.commit('dialog/showDialog', error);
                })
        },
        heartClicked(image)
        {
            if(!this.status.loggedIn) {
                this.$store.commit('dialog/showDialog', "U dient ingelogd te zijn om foto's te kunnen liken.");
                return;
            }
            this.isLikingPhoto = true;
            this.currentLikedPhotoId = image.photoId;
            const likedByCurrentAccount = !image.likedByCurrentAccount;
            this.$store.dispatch('galleryModule/likePhoto', {
                    accountId: this.userId,
                    photoId: image.photoId,
                    like: likedByCurrentAccount
                })
                .then(() => {
                    image.likedByCurrentAccount = likedByCurrentAccount;
                    image.likeCount = image.likedByCurrentAccount ? image.likeCount + 1 : image.likeCount - 1;
                    this.isLikingPhoto = false;
                    this.currentLikedPhotoId = '';
                })
                .catch(() => {
                    this.isLikingPhoto = false;
                    this.currentLikedPhotoId = '';
                })
        },
        showPictureDialog(img)
        {
            this.$store.commit('dialog/showPictureDialog', img);
        },
        nextPage(page) {
            this.pageNumber = page;

            let view = document.querySelector('.tab-section');
            if (view)
            {
                view.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
            }
        },
        editReview()
        {
            this.isOnEditOwnReviewMode = true;
        },
        updateReview(review)
        {
            review.id = review.reviewId;
            this.isOnEditOwnReviewMode = false;
            this.$store.dispatch('reviewModule/updateReview', review)
                .then(() => {
                    review.placed = this.getToday();
                })
                .catch(error => {
                    this.$store.commit('dialog/showDialog', error);
                })
        },
        showDialog(review, index)
        {
            this.isRemoving = false;
            this.selectedReview = review;
            this.selectedIndex = index;
            this.dialog = true;
        },
        deleteReview()
        {
           
            this.isRemoving = true;
            this.$store.dispatch('reviewModule/removeReview', this.selectedReview)
                .then(() => {
                    this.$delete(this.reviews, this.selectedIndex);
                    this.canPlaceReview = true;
                    this.dialog = false;
                })
                .catch(error => {
                    this.dialog = false;
                    this.$store.commit('dialog/showDialog', error);
                })
        }
    }
  }
</script>

<style>
.photoImage
{
    height: 350px;
}

.xs .photoImage
{
    height: 250px;
}

.intro
{
    margin-top: -10px;
}

.photo-review-box
{
    position: relative;
}
.like-counter
{
    position: absolute;
    right: 5px;
    bottom: 15px;
    padding-bottom: 15px;
    z-index: 6;
    text-shadow: 1px 2px 2px #000000;
}

.like-counter .liked
{
    color: red !important;
}

.trip-galerij-header {
    font-size: 32px;
    font-weight: 400;
}

.trip-galerij-subtitle {
    margin: auto;
    font-size: 18px;
}

.xl .photo-review-box,
.lg .photo-review-box,
.md .photo-review-box 
{
    padding: 0px 8px;
}

.photo-container
{
    position: relative;
}

.review-text
{
    margin-bottom: 15px;
}

.review-date
{
    font-size: smaller;
}

.review-text .v-text-field
{
       padding-top: 0px !important;
    margin-top: -3px !important;
    font-size: 18px;
}
</style>

